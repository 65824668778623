<template>
  <div 
    ref="cloudTags"
    :class="[
      'filter-ctn__cloud-tags_inner',
      { 
        'filter-ctn__cloud-tags_scrollTop': isScrollHide,
        'small-style': isSmallStyle,
      }]"
  >
    <div
      class="filter-ctn__cloud-tags"
      :style="styles"
    >
      <swiper-container 
        ref="cloudTagsSwiper"
        class="swiper-tag__container"
        init="false"
        destroy-on-disconnected="false"
      >
        <swiper-slide
          v-for="(item, index) in cloudTagsData"
          :key="`${item.type}_${item.value}`"
          class="swiper-tag__slide"
        >
          <CloudTagQs 
            v-if="item.isNewQs"
            :item="item"
            role="button"
            :aria-label="item.label"
            tabindex="0"
            :scene="scene"
            :active="activeTagIds.includes(item.value)"
            @click="cloudTagClick(item, index)"
          />
          <CloudTagsCccConfig
            v-else-if="item.cccConfig"  
            :item="item"
            role="button"
            :aria-label="item.label"
            tabindex="0"
            :scene="scene"
            :active="activeTagIds.includes(item.value)"
            @click="cloudTagClick(item, index)"
          />
          <span
            v-else
            v-expose="setCategoryExposeAnalysis({ category_id: item.value, index})"
            class="cloud-tags__select-item"
            :class="{
              'cloud-tags__slide-outer': config.showChildren,
              'cloud-tags__slide-outer_has-child': config.showChildren && item.children && item.children.length,
              'cloud-tags__slide-outer_rotate': config.showChildren && item.children && item.children.length && item.value == expandInnerCateId,
              'select-active': isItemSelected(item),
              'attr-select-active': item.active,
              'swiper-slide_popover': item.isPopover,
              'promotion-tag': item.promotion_tag,
              'promotion-tag_active': item.promotion_tag && isSelected(item),
              'mall-tag': item.mall_tag || item.quickship_tag,
              'mall-tag_active': (item.mall_tag || item.quickship_tag) && isSelected(item),
              'panel-content-select': item.panelContentSelected,
              'cloud-tags__attrs': item.dataType, // 属性标签
              'black-friday-tag': item.is_black_friday_tag, // 年末促销
              'black-friday-tag_active': item.is_black_friday_tag && isSelected(item)
            }"
            role="button"
            :aria-label="item.label"
            :style="itemStyles(item)"
            tabindex="0"
            @keydown.enter="cloudTagClick(item, index)"
            @click.stop="cloudTagClick(item, index)"
          >
            <i
              v-if="!isExportTag(item) && isSelected(item) && !isRW && (!config.showChildren || (config.showChildren && (!item.children || !item.children.length)))"
              :class="{
                'cloud-tags__select-cancel': true,
                'rotate': cssRight,
                'cloud-tags__select-cancel_promotion': item.promotion_tag,
                'cloud-tags__select-cancel_mall': item.mall_tag || item.quickship_tag
              }"
              :style="itemCancelStyles(item)"
            ></i>
            <!-- 自定义前置的icon -->
            <i
              v-if="item.icon"
              :class="['suiiconfont', item.icon]"
            ></i>
            <span
              class="cloud-tags__txt"
              :class="{
                'cloud-tags__txt_redpoint': item.isDot && !hideDot,
                'cloud-tags__txt_gap': item.icon
              }"
            >
              {{ config.showChildren && isInnerChildSelected(item) ? isInnerChildSelected(item).label : item.label }}
            </span>
            <!-- 筛选的数量 -->
            <span 
              v-if="item.selectedNum"
              class="cloud-tags__selected-num"
            >({{ item.selectedNum }})</span>
            <!-- 自定义后置的icon -->
            <template v-if="item.iconSuffix">
              <i
                :class="[
                  'attrs-tag',
                  'cloud-tags__attrs-tag',
                  (constantData.is_include_critical_css && !item.active) ? (item.iconSuffix.isSuiIcon ? 'suiiconfont-critical' : 'iconfont-critical') : (item.iconSuffix.isSuiIcon ? 'suiiconfont' : 'iconfont'),
                  item.active ? (item.iconSuffix.active || item.iconSuffix.normal) : item.iconSuffix.normal
                ]"
                :style="item.styles"
              ></i>
            </template>
          </span>
        </swiper-slide>
      </swiper-container>
      <ClientOnly>
        <!-- 新手指引气泡（收藏页使用） -->
        <transition name="S-animation__popover">
          <div
            v-show="showPopover"
            class="cloud-tags__popover"
            :style="popoverStyle"
          >
            <div>
              <span>{{ popText }}</span>
              <i 
                class="cloud-tags__popover-close suiiconfont sui_icon_close_12px_2" 
                @tap="closePopover"
              ></i>
            </div>
            <i
              :style="popverTriangleStyle"
              class="triangle"
            ></i>
          </div>
        </transition>
      </ClientOnly>
    </div>

    <ClientOnly>
      <S-slide
        v-if="config.showChildren"
        ref="sSlideEl"
        :visible.sync="isExpandInnerCate"
        :modal="true"
        @closed="handleSlideClosed()"
      >
        <div class="cloud-tags__slide">
          <div class="cloud-tags__slide-title">
            {{ expandInnerCateId && cloudTagsData.find(i => i.value == expandInnerCateId).label }}
          </div>
          <span
            class="cloud-tags__slide-item cloud-tags__select-item"
            :class="{
              'select-active': tagId == expandInnerCateId,
            }"
            @click="cloudTagClick(cloudTagsData.find(i => i.value == expandInnerCateId), 0, 'isInner')"
          >
            <i
              v-if="tagId == expandInnerCateId && !isRW"
              class="cloud-tags__select-cancel"
              :class="{'rotate': cssRight}"
            ></i>
            {{ config.language.SHEIN_KEY_PWA_18819 }}
          </span>
          <span
            v-for="(item, index) in activeChildren"
            :key="`${item.type}_${item.value}`"
            class="cloud-tags__slide-item cloud-tags__select-item"
            :class="{
              'select-active': isSelected(item),
            }"
            @click="cloudTagClick(item, index, 'isInner', 'isChild')"
          >
            <i
              v-if="isSelected(item) && !isRW"
              class="cloud-tags__select-cancel"
              :class="{'rotate': cssRight}"
            ></i>
            <span class="cloud-tags__txt">
              {{ item.label }}
            </span>
          </span>
        </div>
      </S-slide>
    </ClientOnly>

    <slot></slot>
  </div>
</template>

<script>
import Vue from 'vue'
import { Slide, Popover } from '@shein/sui-mobile'
import ClientOnly from 'vue-client-only'
import CloudTagsCccConfig from './cloud_tags/cccConfig/index.vue'
import CloudTagQs from './cloud_tags/CloudTagQs.vue'
import { setAutoSticky } from '../utils'
import { runDelay } from '../../../../js/utils'
import { register } from 'swiper/element'

Vue.use(Slide)
Vue.use(Popover)
typeof window !== 'undefined' && register()

export default {
  name: 'CloudTags',
  components: {
    ClientOnly,
    CloudTagQs,
    CloudTagsCccConfig,
  },
  props: {
    reload: Boolean, // 首次进入
    loading: {
      type: Boolean,
      default: false
    },
    cloudTags: {
      type: [Object, Array],
      default: () => ({})
    },
    popText: {
      type: String,
      default: ''
    },
    /* {
      showChildren: Boolean, // 通过下拉slide展示子项
      language: Objecy
    } */
    queryParams: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    },
    popDelay: {
      type: Number,
      default: 1000
    },
    resetData: {
      type: Boolean,
      default: false
    },
    // 默认选中项
    defaultSelectedId: {
      type: [Number, String],
      default: ''
    },
    scene: {
      type: String,
      default: ''
    },
    styles: {
      type: Object,
      default: () => ( {} )
    },
    // 红点数据
    redPointData: {
      type: Object,
      default: () => ( {} )
    },
    constantData: {
      type: Object,
      default: () => ( {} )
    },
    attrs: {
      type: Array,
      default: () => []
    },
    filterBarPopupsVisible: {
      type: Boolean,
      default: true,
    },
    isRenderingPanel: {
      type: Boolean,
      default: false,
    },
    isScrollHide: {
      type: Boolean,
      default: false
    },
    isSmallStyle: {
      type: Boolean,
      default: false
    },
    splitGoodsRender: {
      type: Boolean,
      default: false
    },
    isAutoSticky: {
      type: Boolean,
      default: false
    },
    autoStickyParams: {
      type: Object,
      default: () => ({})
    },
  },
  provide () {
    return {
      constantData: this.constantData
    }
  },
  data () {
    return {
      hideDot: false, // 红点点击后就不展示
      tagId: '',  // 选中的标签云
      showPopover: false,
      popoverStyle: {},
      popverTriangleStyle: {},

      isExpandInnerCate: false,
      expandInnerCateId: '',
      savedIndex: [],
    }
  },
  computed: {
    isCloudTagsObject () {
      return Object.prototype.toString.call(this.cloudTags) === '[object Object]'
    },
    cloudTagsData () {
      return Array.isArray(this.cloudTags) && this.cloudTags.length ? this.cloudTags : this.cloudTags?.data || []
    },
    activeChildren() {
      if (this.expandInnerCateId && this.cloudTagsData.find(i => i.value == this.expandInnerCateId)) {
        return this.cloudTagsData.find(i => i.value == this.expandInnerCateId).children
      } else {
        return []
      }
    },
    activeIndex () {
      return this.cloudTagsData.findIndex(item => this.tagId == item.value)
    },
    curValue () {
      return this.isCloudTagsObject && this.cloudTags.curValue // 侧边通过地址引用联动过来的
    },
    cssRight () {
      return this.constantData.GB_cssRight
    },
    isRW () {
      return this.constantData.IS_RW
    },
    attrList() {
      return this.cloudTagsData.filter(item => item.dataType)
    },
    activeTagIds () {
      return [
        this.tagId, 
        this.queryParams.tag_ids, 
        this.queryParams.pic_cate_id
      ]
    }
  },
  watch: {
    resetData (isReset) {
      if (isReset) {
        this.resetCloud()
        this.$emit('update:resetData', false)
      }
    },
    cloudTagsData: {
      handler () {
        this.initDefaultSelected()
        if (typeof window === 'undefined') return
        this.initSwiper()
      }
    },
    redPointData: {
      handler () {
        const index = this.redPointData.index
        if (!index && index !== 0) return

        this.$set(this.cloudTagsData[index], 'isDot', true)

        if (!this.redPointData.popDisabled) {
          this.$set(this.cloudTagsData[index], 'isPopover', true)

          this.$nextTick(() => {
            this.handlePopover()
          })
        }
      },
      immediate: true
    },
    curValue () {
      this.tagId = this.curValue
      const index = this.cloudTagsData.findIndex(cloudTag => cloudTag.value == this.tagId)
      if (index != -1 && this.cloudTagsData[index].isDot) {
        this.$set(this.cloudTagsData[index], 'isDot', false)
        this.hideDot = true
      }
    },
    isExpandInnerCate(isShow) {
      if (isShow) {
        this.originalBodyOverflow = document.body.style.overflow
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = this.originalBodyOverflow || ''
      }
    },
    queryParams(val) {
      if (val.tag_ids !== this.tagId) {
        this.tagId = val.tag_ids
      }
    }
  },
  async mounted () {
    window.$ct = this
    this.initEvent()
    if (this.filterBarPopupsVisible) {
      this.initPanelData()
    }
    this.initDefaultSelected()

    this.initSwiper()
  },
  beforeDestroy () { 
    this.cloudTagsSwiper?.destroy?.()
  },
  methods: {
    initEvent () {
      this.$on('clickOverlay', this.clickOverlay)
      this.$on('clickMenuItem', this.clickMenuItem)
      this.$on('clickDone', this.clickDone)
      this.$on('singleSelectChange', this.singleSelectChange)
      this.$on('multiSelectChange', this.multiSelectChange)
      this.$on('clickSideFilterCloseIcon', this.clickSideFilterCloseIcon)
      // this.$on('sidePanelChange', this.sidePanelChange)
      this.$on('resetAll', this.resetAll)
      this.$on('recordSelectMenu', this.recordSelectMenu)
      this.$on('setFilterBadgeNum', this.setFilterBadgeNum)
      this.$on('rangeChange', this.filterChange)
      // this.$on('needHighlightNavBar', this.needHighlightNavBar)
      // this.$on('priceSlideChange', this.priceSlideChange)

      if (typeof window !== 'undefined') {
        appEventCenter.$on('hidePanel', this.hideAllPanel)
      }
    },
    // 初始化panel数据，记录panel的index
    initPanelData () {
      this.reload = true
      const panelSlots = this.$slots?.default?.filter(_=>_.componentInstance) || []
      panelSlots.forEach((panel, index) => {
        panel.componentInstance?.recordIndex(index)
      })
    },
    getSwiperInstance () { 
      return this.$refs.cloudTagsSwiper.swiper
    },
    swiperComputedSlideIndex () {
      let beginIndex = null
      let endIndex = null
      const swiperInstance = this.getSwiperInstance()
      if (!swiperInstance) return

      if (!swiperInstance.slidesSizesGrid) return { beginIndex, endIndex }

      swiperInstance.slidesSizesGrid.reduce((acc, cur, index) => {
        acc += cur
        let resetAcc = acc - Math.abs(swiperInstance.translate)
        // 有位移，减去位移的长度，获取下一个tab的索引，即resetAcc 大于0
        if (resetAcc <= 0) return acc
        if (beginIndex === null) beginIndex = index
        // 贪婪判断 （只要当前tab有一部分（最左边开始）在屏幕上即算曝光）
        if (resetAcc - cur <= swiperInstance.width) endIndex = index
        return acc
      }, 0)
      return {
        beginIndex,
        endIndex
      }
    },
    itemStyles(item) {
      if (!this.isItemSelected(item)) {
        return {}
      }
      const selectedStyle = this.itemSelectedStyles(item)
      if (!selectedStyle) return {}
      const {
        backgroundColor,
        borderColor,
        color,
      } = selectedStyle
      return {
        backgroundColor,
        borderColor,
        color,
      }
    },
    itemCancelStyles(item) {
      if (!this.isItemSelected(item)) {
        return {}
      }
      const selectedStyle = this.itemSelectedStyles(item)
      if (!selectedStyle) return {}
      const { borderColor } = selectedStyle
      return {
        borderColor: `transparent ${borderColor} transparent transparent`,
      }
    },
    itemSelectedStyles(item) {
      if (!item.selectedStyles) return
      return {
        backgroundColor: item.selectedStyles.backgroundColor ?? '#fff',
        borderColor: item.selectedStyles.borderColor ?? '#222',
        color: item.selectedStyles.color ?? '#222',
      }
    },
    isItemSelected(item) {
      return !this.isExportTag(item) && this.isSelected(item) || (this.config.showChildren && this.isInnerChildSelected(item))
    },
    initSwiper () {
      this.splitGoodsRender
        ? appEventCenter.$once('goodsLoadedOnPageList', () => runDelay(this.swiperInitialize))
        : runDelay(this.swiperInitialize)
    },
    swiperInitialize () { 
      if (this?.cloudTagsSwiper?.initialized) {
        this.cloudTagsSwiper.update()
        this.cloudTagsSwiper.slideTo(this.cloudTagsSwiper.activeIndex, 300)
        return
      }
      const self = this
      const swiperEl = this.$refs.cloudTagsSwiper
      const swiperParams = {
        slidesPerView: 'auto',
        observer: true,
        initialSlide: 0,
        centeredSlides: true,
        slideToClickedSlide: true,
        setWrapperSize: true,
        on: {
          init (swiperInstance) {
            self.$nextTick(() => {
              const { beginIndex, endIndex } = self.swiperComputedSlideIndex()
              self.$emit('onSwiperInit', beginIndex, endIndex)
              $(window).scroll()
            })
            swiperInstance.setTranslate(0)
          },
          transitionStart (swiperInstance) {
            let translateX
            let minTranslateX = 0
            let maxTranslateX
            if (typeof maxTranslateX === 'undefined') {
              const translateX =  $(swiperInstance.wrapperEl).css('width').replace('px', '') - swiperInstance.width
              maxTranslateX = translateX > 0 ? translateX : 0
            }

            if (self.cssRight) {
              if (swiperInstance.translate > maxTranslateX) translateX = -maxTranslateX
              if (swiperInstance.translate < minTranslateX) translateX = minTranslateX
            } else {
              minTranslateX = -maxTranslateX
              maxTranslateX = 0
              if (swiperInstance.translate > maxTranslateX) translateX = maxTranslateX
              if (swiperInstance.translate < minTranslateX) translateX = minTranslateX
            }

            if (typeof translateX !== 'undefined') {
              swiperInstance.setTranslate(translateX)
            }
          },
          slideChangeTransitionEnd: () => {
            self.$nextTick(() => {
              const { beginIndex, endIndex } = self.swiperComputedSlideIndex()
              self.$emit('onSwiperSlideEnd', beginIndex, endIndex)
              $(window).scroll()
            })
          }
        },
      }

      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.cloudTagsSwiper = swiperEl.swiper
    },
    // 回显数据
    initDefaultSelected () {
      this.tagId = ''
      if (this.defaultSelectedId || this.defaultSelectedId === 0) {
        this.tagId = this.defaultSelectedId
      }
      this.$nextTick(() => {
        this.slideToSelectedTag()
      })
    },
    
    slideToSelectedTag () {
      if (this.cloudTagsSwiper && (this.tagId || this.reload)) {
        this.$emit('onReload', false)
        // this.cloudTagsSwiper?.update()
        const selectedIndex = this.cloudTagsData.findIndex(item => item.value == this.tagId)
        if  (selectedIndex > -1) {
          this.cloudTagsSwiper.slideTo(selectedIndex, 300)
        } else {
          this.cloudTagsSwiper.slideTo(0, 300)
        }
      }
    },
    handlePopover () {
      const popoverEl = document.querySelector('.swiper-slide_popover')

      if (!popoverEl) return

      let triangleLeft = ''
      let popoverLeft = ''
      const direction = this.cssRight ? 'right' : 'left'
      const curPopoverElleft = popoverEl.getBoundingClientRect()[direction]
      const popoverElWidth = popoverEl.offsetWidth
      const windowWidth = $(window).width()
      const offsetLeft = this.cssRight ? windowWidth - curPopoverElleft : curPopoverElleft

      if (windowWidth - offsetLeft < 220) {
        popoverLeft = windowWidth - 220
        const difference = this.cssRight ? 220 - curPopoverElleft : curPopoverElleft - popoverLeft
        triangleLeft = popoverElWidth / 2 + difference
      } else {
        popoverLeft = offsetLeft
        triangleLeft = popoverElWidth / 2
      }

      Object.assign(this.popverTriangleStyle, { [direction]: `${triangleLeft}px` })
      Object.assign(this.popoverStyle, { [direction]: `${popoverLeft}px` })

      this.showPopover = true

      setTimeout(() => {
        this.showPopover = false
      }, this.popDelay + 300)
    },
    isInnerChildSelected(item) {
      return item.children && item.children.find(it => this.tagId == it.value)
    },
    attrCloudTagClick(item, activeIndex) {
      if (this.isRenderingPanel) {
        return
      }
      const payload = {
        item,
        activeIndex,
      }
      if (this.filterBarPopupsVisible) {
        this.onPanelMounted(payload)
      } else {
        this.$emit('renderPanel', payload)
      }
    },
    onPanelMounted({ item, activeIndex }) {
      if (item.disable) return
      // 激活高亮和icon
      this.attrList.forEach((obj, idx) => {
        if (activeIndex != idx) {
          this.$set(obj, 'active', false)
        }
      })
      if (!this.attrList[activeIndex]) return
      const activeStatus = !this.attrList[activeIndex].active
      this.$set(this.attrList[activeIndex], 'active', activeStatus)

      if (activeStatus) {
        const target = document.body.offsetHeight - this.$refs.cloudTags?.getBoundingClientRect().bottom
        if (target < 240) {
          window.scrollTo(0, window.scrollY - target + 250)
        }
      }

      const panelSlots = this.$slots?.default?.filter(_=>_.componentInstance) || []
      // 控制panel展开收起
      panelSlots.forEach((panel, index) => {
        if (activeIndex !== index) {
          panel.componentInstance.show && panel.componentInstance.close()
        }
      })
      panelSlots[activeIndex].componentInstance.toggle()
      
      this.$emit('clicAttrTag', { ...item, show: panelSlots[activeIndex].componentInstance.show })
    },
    async cloudTagClick (item, index, isInner, isChild) {
      if (this.loading) return
      if (this.isAutoSticky) {
        this.$emit('autoStickyBefore')
        const flag = await setAutoSticky(this.autoStickyParams)
        flag && this.$emit('autoSticky')
        this.$emit('autoStickyAfter')
        await this.$nextTick()
      }
      
      this.$emit('wishlistfilterType', 'CloudTag')
      if (item.dataType) { // 属性标签
        this.attrCloudTagClick(item, this.attrList.findIndex(obj => obj === item))
       
        return
      }

      if (this.config.showChildren && item.children && item.children.length && !isInner) {
        if (this.expandInnerCateId != item.value) {
          this.expandInnerCateId = item.value
          this.$nextTick(() => {
            this.isExpandInnerCate = true
            this.$emit('sendCloudSlideStatus', true)
            this.$emit('handelChildIdExpose', { activeChildren: this.activeChildren, parent_cat_id: `${index + 1}\`${item.value}` })
          })
        } else {
          this.isExpandInnerCate = false
        }
      } else {
        this.isExpandInnerCate = false
        // 红点
        if (item.isDot) {
          if (this.hideDot) {
            this.$set(item, 'isDot', false)
          }
          this.hideDot = true
        }

        const parentIndex = this.cloudTagsData.findIndex(i => i.value == this.expandInnerCateId)
        const params = {
          tagIndex: index + 1,
          curClickValue: `${index + 1}\`${item.value}`,
          curClickLabel: item.label,
          isChild: '0',
          parent_cat_id: `${parentIndex + 1}\`${this.expandInnerCateId}`
        }
        if(isChild) params.isChild = '1'
        // 标签云选择/取消
        // NOTE: wishlist queryParams 是否没传
        const currentSelectStatus = Object.keys(this.queryParams).length 
          ? ![this.queryParams.tag_ids, this.queryParams.pic_cate_id].includes(item.value)
          : this.tagId != item.value
        if (currentSelectStatus) {
          this.tagId = item.value
          Object.assign(params, item, { cancel: 0 })
        } else {
          this.tagId = ''
          Object.assign(params, item, { cancel: 1, value: this.tagId })
        }

        if (this.isCloudTagsObject) {
          this.$set(this.cloudTags, 'curValue', this.tagId)
        }
        // todo: 重新计算宽度， 因为内容产生改变
        this.slideToSelectedTag()

        const payload = { data: params, type: 'cloudTag' }
        this.$emit('cloudTagsChange', payload)
      }

      this.$nextTick(() => {
        this.$refs['sSlideEl']?.calHeight?.()
      })
    },
    resetCloud () {
      this.tagId = ''
    },
    handleSlideClosed() {
      this.expandInnerCateId = ''
      this.$emit('sendCloudSlideStatus', false)
    },
    // 注意： 需要上报记得注册埋点
    setCategoryExposeAnalysis({ category_id, index }) {
      if(this.scene !== 'flash') return
      const data = {
        category_id: `${index + 1}\`${category_id}`,
      }
      return {
        id: '2-17-27',
        code: 'FlashSaleCategoryId',
        prefix: 'FlashSale_Category_Id',
        delayReady: 1500,
        data
      }
    },
    // 点击黑色透明遮罩层
    clickOverlay () {
      this.resetArrow()
    },
    /**
     * payload: 筛选参数
     * from: 触发方式。default: 默认选中
     */
    clickMenuItem ({ payload, index, from, item }) {
      this.resetArrow()
      const _from = ['default']
      if (!_from.includes(from)) {
        this.filterChange({ ...payload, index, item })
      }

      if (!this.keepHighlight) return
      
      this.handleHighlightBar({ curIndex: index, curBarHighlight: true })
    },
    // 重置默认 箭头 和 当前点击开的panel的index
    resetArrow () {
      this.attrList.forEach(item => this.$set(item, 'active', false))
    },
    recordSelectMenu (panelIndex) {
      this.savedIndex.push(panelIndex)
    },
    /**
     * SingleSelect组件，选择项变化
     * payload 数据
     */
    singleSelectChange (payload) {
      const { data, from, index } = payload
      const { value } = data
      // url回显或者调用方主动调用筛选时无需透传出去
      const _from = ['url', 'propsCalled']
      if (!_from.includes(from)) {
        this.filterChange(payload)
      }

      if (!this.keepHighlight) return

      this.handleHighlightBar({ curIndex: index, curBarHighlight: !!value })
    },
    /**
     * multiSelect组件，选择项变化
     * data 选中的筛选项
     */
    multiSelectChange (payload) {
      const { from } = payload

      const _from = ['url', 'propsCalled']
      if (!_from.includes(from)) {
        this.filterChange(payload)
      }
    },
    clickDone () {
      this.resetArrow()
    },
    clickSideFilterCloseIcon () {
      this.resetArrow()
    },
    // sidePanelChange (payload) {
    //   const { type, index } = payload
    //   const highlightParams = { curIndex: index }
    //   if (type == 'single') {
    //     Object.assign(highlightParams, { curBarHighlight: !!this.filterBadgeNum })
    //   } else if (type == 'multi' || type == 'img') {
    //     Object.assign(highlightParams, { curBarHighlight: !!this.filterBadgeNum })
    //   } else if (type == 'price') {
    //     Object.assign(highlightParams, { curBarHighlight: !!this.filterBadgeNum })
    //   }
    //   this.filterChange(payload)

    //   if (payload.dataType === 'cate') {
    //     daEventCenter.triggerNotice({
    //       daId: '1-5-1-30',
    //       extraData: Object.assign({
    //         category_id: payload.value ?? ''
    //       }, payload)
    //     })
    //   }
    //   if (!this.keepHighlight) return

    //   this.handleHighlightBar(highlightParams)
    // },
    /**
     * 左侧弹出面板点击reset
     * menuItems 每项内容
     * propsCalled 是否是外部主动调用重置
     *  */
    resetAll ({ operation, index, menuItems, outClick }) {
      this.resetDefault(menuItems)
      if (!outClick) {
        this.filterChange({ operation, index })
      }
    },
    // 恢复默认不选中状态
    resetDefault () {
      this.handleHighlightBar({ reset: true })
    },
    handleHighlightBar ({ curBarHighlight, curIndex, reset }) {
      if (!this.attrList.length) return

      if (reset) {
        this.attrList.forEach((item, idx) => {
          if (!this.savedIndex.includes(idx)) {
            this.$set(item, 'panelContentSelected', false)
          }
        })
        return
      }

      const indexArr = []
      if (curIndex || curIndex === 0) {
        indexArr.push({ index: curIndex, highlight: curBarHighlight })
      }

      indexArr.forEach(item => {
        this.$set(this.attrList[item.index], 'panelContentSelected', item.highlight)
      })
    },
    setFilterBadgeNum (num) {
      this.filterBadgeNum = num
    },
    // needHighlightNavBar ({ index, highlight }) {
    //   this.handleHighlightBar({ curIndex: index, curBarHighlight: highlight })
    // },
    filterChange (payload) {
      // 标签位置
      const tagIndex = this.cloudTagsData.findIndex(item => item === payload.item) + 1

      this.$emit('onChange', {
        ...payload,
        tagIndex
      })
    },

    // 隐藏panel
    hideAllPanel () {
      this.resetArrow()
      const panelSlots = this.$slots?.default?.filter(_=>_.componentInstance) || []
      panelSlots.forEach((panel) => {
        panel?.componentInstance?.close()
      })
    },
    closePopover() {
      this.showPopover = false
    },
    // 手动调用显示
    showPopoverByIndex(index) {
      this.$set(this.cloudTagsData[index], 'isPopover', true)
      this.$nextTick(() => {
        this.handlePopover()
      })
    },
    closeSlide() {
      if (this.isExpandInnerCate) {
        this.isExpandInnerCate = false
      }
    },
    // 是否外露标签
    isExportTag(item) {
      return ['attr', 'tsp', 'cate', 'price'].includes(item.dataType)
    },
    // 是否已选标签
    isSelected(item) {
      return [
        this.tagId, 
        this.queryParams.tag_ids, 
        this.queryParams.pic_cate_id
      ].includes(item.value)
    }
  }
}
</script>

<style lang="less" scoped>
.filter-ctn {
  &__cloud-tags {
    position: relative;
    background-color: #fff;
    padding: 0.32rem .32rem;
    transition: all .2s ease-out;
    border-bottom: 1px solid #fff;
    transform: none;
    /*rtl:begin:ignore*/
    .zindex-translatez(@zindex-header + 1, translate3d(0, 0, @zindex-header + 1));
    
    .wiper-container_more {
      margin-right: .747rem;
    }
    
    &_inner {
      transition: all .2s ease-out;
      position: relative;
      top: -0.026667rem;
    }
    &_scrollTop {
      opacity: 0;
      transition: 'all .3s';
      transform: translate3d(0, -3rem, 0);
    }
    .cloud-tags {
      &__txt {
        position: relative;
        display: inline-block;
        white-space: nowrap;
        &_redpoint {
          &:after {
            content: '';
            position: absolute;
            top: 1px;
            right: -.16rem;
            width: .16rem;
            height: .16rem;
            border-radius: 50%;
            background-color: @sui_color_guide;
          }
        }
        &_gap {
          margin-left: 3px;
        }
      }

      &__popover {
        position: absolute;
        top: 1.3rem;
        z-index: @zindex-transform1;
        width: 100%;
        // animation: pop-down 0.5s ease;
        // >div {
        //   width: 220px;
        // }
        .triangle {
          position: absolute;
          top: -.32rem;
          transform: translateX(-50%);
          border: .16rem solid rgba(0, 0, 0, 0.8);
          border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
        }
        >div {
          position: absolute;
          left: 0;
          white-space: nowrap;
          border-radius: .05rem;
          padding: .26rem .32rem;
          background: rgba(0, 0, 0, 0.8);

          max-width: 6.4rem;
          white-space: pre-wrap;

          font-size: .293rem;
          color: #fff;
        }
      }
      
      &__popover-close {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 0 .1rem .1rem;
        width: .42rem;
        height: .42rem;
        line-height: .32rem;

        color: #fff;
      }
      &__selected-num {
        margin-left: 0.0533rem;
      }
    }
  }
}
.small-style {
  .filter-ctn__cloud-tags {
    padding: 6.5 / 37.5rem 0.32rem 8 / 37.5rem;
  }
  .cloud-tags__select-item {
    height: 27 / 37.5rem;
    line-height: 27 / 37.5rem;
  }
}

.cloud-tags {
  &__select-cancel {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 16px 16px 0;
    border-color: transparent @color_brand transparent transparent;
    &_promotion {
      border-color: transparent @sui_color_welfare_dark transparent transparent;
    }
    &_mall {
      border-color: transparent @sui_color_safety transparent transparent;
    }
    &::before {
      position: absolute;
      top: -1px;
      .right(-18px);
      line-height: 1;
      content: 'x';
      width: 12px;
      height: 12px;
      line-height: 12px;
      color: #fff;
      text-align: center;
      transform: scale(0.8);
    }
  }
  &__select-item {
    .flexbox();
    .align-center();
    position: relative;
    width: auto !important; /* stylelint-disable-line declaration-no-important */
    background: #f6f6f6;
    padding: 0 .32rem;
    text-align: center;
    color: #666;
    font-size: 12px;
    line-height: .6933rem;
    margin-right: .2rem;
    border: 1px solid #fff; // 解决页面抖动
    [class*="iconfont"] {
      display: inline-block;
      min-width: 12px;
      color: #d53333 /* rw: #f99 */;
    }
    >.attrs-tag.cloud-tags__attrs-tag {
      color: #666;
    }

    &.attr-select-active .cloud-tags__attrs-tag {
      color: #000 /* rw: #FC4070 */;
    }

    &.panel-content-select .cloud-tags__attrs-tag {
      color: #000 /* rw: #FC4070 */;
    }

    &.select-active {
      color: @sui_color_main;
      border: 1px solid @sui_color_main;
      /* rw:begin */
      background: #FFF7F9;

    }
    &.attr-select-active {
      color: #000;
      border: 1px solid #000;
      /* rw:begin */
      color: @sui_color_discount;
      border: 1px solid @sui_color_discount;
      background: #FFF7F9;
    }

    &.panel-content-select {
      color: #000 /* rw: #FC4070 */;
      border: 1px solid #000;
      /* rw:begin */
      border: 1px solid @sui_color_discount;
    }

    &.promotion-tag {
      &_active{
        color: @sui_color_welfare_dark;
        border: 1px solid @sui_color_welfare_dark;
      }
      .suiiconfont {
        color: @sui_color_welfare_dark;
        font-size: 12px;
      }
    }
    &.mall-tag {
      &_active {
        color: @sui_color_safety;
        border: 1px solid @sui_color_safety;
      }
      .suiiconfont {
        color: @sui_color_safety;
        font-size: 12px;
      }
    }

    &.black-friday-tag {
      &_active {
        background: @sui_color_promo_bg;
        color: @sui_color_promo_dark;
        border: 1px solid @sui_color_promo_dark;
      }
      .cloud-tags__select-cancel {
        border-color: transparent @sui_color_promo_dark transparent transparent;
        font-size: 12px;
      }
    }


    &.cloud-tags__attrs {
      .suiiconfont {
        color: #666;
        font-size: 12px;
      }
    }
    &.attr-select-active {
      .suiiconfont {
        color: @sui_color_main;
      }
    }
  }
  &__slide-outer {
    background-color: #fff;
    border: 1px solid #E5E5E5;
    &_has-child {
      padding-right: 0.6933rem;
      &:after {
        position: absolute;
        right: 0.32rem;
        top: 50%;
        transform: translateY(-50%);
        transition: transform .2s;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.1067rem 0.1067rem 0 0.1067rem;
        border-color: #222 transparent transparent transparent;
      }
    }
    &_rotate {
      &:after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  &__slide {
    position: relative;
    padding: 2px 0.32rem 0.2133rem;
    width: 100%;
    background-color: #fff;
  }
  &__slide-title {
    margin-bottom: 0.2133rem;
    color: #666;
  }
  &__slide-item {
    display: inline-block;
    margin-bottom: 0.2133rem;
  }
}

.swiper-tag{
  &__container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    // height: 27 / 37.5rem;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  &__slide {
    width: auto;
    flex-shrink: 0;
    // width: calc(100% / var(--slides-per-view));
  }
}

@keyframes pop-down {
  0% {
    transform: translateY(-.32rem);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
