<!-- 标签云新增QS标签强化实验abt[pageId=1394253484] -->
<template>
  <div
    v-expose="exposeData"
    class="cloud-tag__qs"
    :style="styles"
    @click="$emit('click')"
  >
    <span class="cloud-tag__qs-icon">
      <sui_icon_qucikship_flat_16px
        size="14px"
      />
    </span>

    <div>{{ label }}</div>

    <CloudTagActiveIcon
      v-if="active"
      :color="styles.color"
    />
  </div>
</template>

<script setup>
import { toRef, computed } from 'vue'

// components
import { sui_icon_qucikship_flat_16px } from '@shein-aidc/icon-vue2'
import CloudTagActiveIcon from './CloudTagActiveIcon.vue'

// hooks
import { useExpose, useQsStyles } from './cccConfig/hooks/index.js'

const props = defineProps({
  active: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => ({})
  },
  scene: {
    type: String,
    default: ''
  },
})

const exposeData = useExpose(toRef(props, 'scene'), toRef(props, 'item'))
const styles = useQsStyles(toRef(props, 'active'))
const label = computed(() => props.item.cccConfig?.displayContent?.labelLang || props.item.label || props.item.tag_name || '')

</script>

<style scoped lang="less">
  .cloud-tag{
    &__qs {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      width: auto;
      color: #198055;
      font-size: 12px;
      font-style: italic;
      padding: 0 12px;
      margin-right: 8px;
      white-space: nowrap;
      height: 27 / 37.5rem;
      line-height: 27 / 37.5rem;
      border: 1px solid #fff;
    }

    &__qs-icon {
      display: flex;
      color: #198055;
      margin-right: 4px;
    }
  }
</style>
