var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"cloudTags",class:[
    'filter-ctn__cloud-tags_inner',
    { 
      'filter-ctn__cloud-tags_scrollTop': _vm.isScrollHide,
      'small-style': _vm.isSmallStyle,
    }]},[_c('div',{staticClass:"filter-ctn__cloud-tags",style:(_vm.styles)},[_c('swiper-container',{ref:"cloudTagsSwiper",staticClass:"swiper-tag__container",attrs:{"init":"false","destroy-on-disconnected":"false"}},_vm._l((_vm.cloudTagsData),function(item,index){return _c('swiper-slide',{key:`${item.type}_${item.value}`,staticClass:"swiper-tag__slide"},[(item.isNewQs)?_c('CloudTagQs',{attrs:{"item":item,"role":"button","aria-label":item.label,"tabindex":"0","scene":_vm.scene,"active":_vm.activeTagIds.includes(item.value)},on:{"click":function($event){return _vm.cloudTagClick(item, index)}}}):(item.cccConfig)?_c('CloudTagsCccConfig',{attrs:{"item":item,"role":"button","aria-label":item.label,"tabindex":"0","scene":_vm.scene,"active":_vm.activeTagIds.includes(item.value)},on:{"click":function($event){return _vm.cloudTagClick(item, index)}}}):_c('span',{directives:[{name:"expose",rawName:"v-expose",value:(_vm.setCategoryExposeAnalysis({ category_id: item.value, index})),expression:"setCategoryExposeAnalysis({ category_id: item.value, index})"}],staticClass:"cloud-tags__select-item",class:{
            'cloud-tags__slide-outer': _vm.config.showChildren,
            'cloud-tags__slide-outer_has-child': _vm.config.showChildren && item.children && item.children.length,
            'cloud-tags__slide-outer_rotate': _vm.config.showChildren && item.children && item.children.length && item.value == _vm.expandInnerCateId,
            'select-active': _vm.isItemSelected(item),
            'attr-select-active': item.active,
            'swiper-slide_popover': item.isPopover,
            'promotion-tag': item.promotion_tag,
            'promotion-tag_active': item.promotion_tag && _vm.isSelected(item),
            'mall-tag': item.mall_tag || item.quickship_tag,
            'mall-tag_active': (item.mall_tag || item.quickship_tag) && _vm.isSelected(item),
            'panel-content-select': item.panelContentSelected,
            'cloud-tags__attrs': item.dataType, // 属性标签
            'black-friday-tag': item.is_black_friday_tag, // 年末促销
            'black-friday-tag_active': item.is_black_friday_tag && _vm.isSelected(item)
          },style:(_vm.itemStyles(item)),attrs:{"role":"button","aria-label":item.label,"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.cloudTagClick(item, index)},"click":function($event){$event.stopPropagation();return _vm.cloudTagClick(item, index)}}},[(!_vm.isExportTag(item) && _vm.isSelected(item) && !_vm.isRW && (!_vm.config.showChildren || (_vm.config.showChildren && (!item.children || !item.children.length))))?_c('i',{class:{
              'cloud-tags__select-cancel': true,
              'rotate': _vm.cssRight,
              'cloud-tags__select-cancel_promotion': item.promotion_tag,
              'cloud-tags__select-cancel_mall': item.mall_tag || item.quickship_tag
            },style:(_vm.itemCancelStyles(item))}):_vm._e(),_vm._v(" "),(item.icon)?_c('i',{class:['suiiconfont', item.icon]}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"cloud-tags__txt",class:{
              'cloud-tags__txt_redpoint': item.isDot && !_vm.hideDot,
              'cloud-tags__txt_gap': item.icon
            }},[_vm._v("\n            "+_vm._s(_vm.config.showChildren && _vm.isInnerChildSelected(item) ? _vm.isInnerChildSelected(item).label : item.label)+"\n          ")]),_vm._v(" "),(item.selectedNum)?_c('span',{staticClass:"cloud-tags__selected-num"},[_vm._v("("+_vm._s(item.selectedNum)+")")]):_vm._e(),_vm._v(" "),(item.iconSuffix)?[_c('i',{class:[
                'attrs-tag',
                'cloud-tags__attrs-tag',
                (_vm.constantData.is_include_critical_css && !item.active) ? (item.iconSuffix.isSuiIcon ? 'suiiconfont-critical' : 'iconfont-critical') : (item.iconSuffix.isSuiIcon ? 'suiiconfont' : 'iconfont'),
                item.active ? (item.iconSuffix.active || item.iconSuffix.normal) : item.iconSuffix.normal
              ],style:(item.styles)})]:_vm._e()],2)],1)}),1),_vm._v(" "),_c('ClientOnly',[_c('transition',{attrs:{"name":"S-animation__popover"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPopover),expression:"showPopover"}],staticClass:"cloud-tags__popover",style:(_vm.popoverStyle)},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.popText))]),_vm._v(" "),_c('i',{staticClass:"cloud-tags__popover-close suiiconfont sui_icon_close_12px_2",on:{"tap":_vm.closePopover}})]),_vm._v(" "),_c('i',{staticClass:"triangle",style:(_vm.popverTriangleStyle)})])])],1)],1),_vm._v(" "),_c('ClientOnly',[(_vm.config.showChildren)?_c('S-slide',{ref:"sSlideEl",attrs:{"visible":_vm.isExpandInnerCate,"modal":true},on:{"update:visible":function($event){_vm.isExpandInnerCate=$event},"closed":function($event){return _vm.handleSlideClosed()}}},[_c('div',{staticClass:"cloud-tags__slide"},[_c('div',{staticClass:"cloud-tags__slide-title"},[_vm._v("\n          "+_vm._s(_vm.expandInnerCateId && _vm.cloudTagsData.find(i => i.value == _vm.expandInnerCateId).label)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"cloud-tags__slide-item cloud-tags__select-item",class:{
            'select-active': _vm.tagId == _vm.expandInnerCateId,
          },on:{"click":function($event){_vm.cloudTagClick(_vm.cloudTagsData.find(i => i.value == _vm.expandInnerCateId), 0, 'isInner')}}},[(_vm.tagId == _vm.expandInnerCateId && !_vm.isRW)?_c('i',{staticClass:"cloud-tags__select-cancel",class:{'rotate': _vm.cssRight}}):_vm._e(),_vm._v("\n          "+_vm._s(_vm.config.language.SHEIN_KEY_PWA_18819)+"\n        ")]),_vm._v(" "),_vm._l((_vm.activeChildren),function(item,index){return _c('span',{key:`${item.type}_${item.value}`,staticClass:"cloud-tags__slide-item cloud-tags__select-item",class:{
            'select-active': _vm.isSelected(item),
          },on:{"click":function($event){return _vm.cloudTagClick(item, index, 'isInner', 'isChild')}}},[(_vm.isSelected(item) && !_vm.isRW)?_c('i',{staticClass:"cloud-tags__select-cancel",class:{'rotate': _vm.cssRight}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"cloud-tags__txt"},[_vm._v("\n            "+_vm._s(item.label)+"\n          ")])])})],2)]):_vm._e()],1),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }